import { Button, Divider, Input, Spin } from 'antd'
import format from 'date-fns/format'
import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { updateHourRateForMonth, useDebounce, useModuleAccessCheck, useRegistrations, useUserInfo } from '../../fetchHooks/useFetchHooks'
import Navbar from '../Navbar/Navbar'
import Day, { formatCurrency } from './Day'
import { Typography } from 'antd'
import NavMonths from '../Navbar/NavMonths'
import { CenteredContainer } from '../../styles'
import { useQueryClient } from '@tanstack/react-query'
import BlurScreenWhenInactive from '../BlurScreenWhenInactive/BlurScreenWhenInactive'
import { MonthExport } from './MonthExport'
import { Footer } from '../Footer/Footer'

const { Title } = Typography

const RenderDays = ({ month, stats, uniqueWeekNumbers, isExport = false }) => (
  <div>
    {uniqueWeekNumbers?.map((weekNumber) => {
      return (
        <React.Fragment key={weekNumber}>
          <div className={`week-wrapper ${isExport ? 'isExport' : ''}`}>
            <div className={`day-wrapper ${isExport ? 'day-wrapper-export' : ''}`}>
              {!isExport && <p className="workday"></p>}

              <p className="date"></p>
              <p className="weekday">Week {weekNumber}:</p>
              <p className="start">Start</p>
              <p className="stop">End</p>
              <p className="lunch">Lunch</p>
              <p className="ord_hours">Ord. hours</p>
              <p className="overtime_hours">Overtime</p>
              {!isExport && <p className="flexTime">Flex</p>}
              <p className="total">Total</p>
              <p className="brutto">Brutto</p>
            </div>
            {month
              .filter((date) => date.weekNumber === weekNumber)
              .map((date) => (
                <Day date={date} key={date.id} isExport={isExport} />
              ))}
          </div>
          {!isExport && <Divider />}
        </React.Fragment>
      )
    })}
  </div>
)

const Month = () => {
  const [searchParams] = useSearchParams()
  const [showMonth, setShowMonth] = useState(parseInt(searchParams.get('month')))
  const [showYear, setShowYear] = useState(parseInt(searchParams.get('year')))
  const [hourRateInput, setHourRateInput] = useState()
  const hasModuleAccess = useModuleAccessCheck('salary')
  const [showExport, setShowExport] = useState(false)

  const { data: registrations, isSuccess, isPending } = useRegistrations(showYear, showMonth)
  const { data: user } = useUserInfo()
  const hourRateUpdated = hourRateInput?.valueOf() === registrations?.data?.at(1)?.hourRate?.valueOf()
  const navigate = useNavigate()

  useDebounce(
    () =>
      handleChangeHourRate({
        hourRate: hourRateInput,
        id: registrations.data.at(1).id,
        month: registrations.data.at(1).month,
        year: registrations.data.at(1).year
      }),
    hourRateInput
  )

  const queryClient = useQueryClient()

  const handleChangeHourRate = (data = {}) =>
    updateHourRateForMonth({ ...data }, () => {
      queryClient.invalidateQueries(['useRegistrations'])
      queryClient.invalidateQueries(['useYearStats'])
    })

  useEffect(() => {
    showYear && showMonth && navigate(`/registrations?year=${showYear}&month=${showMonth}`)
  }, [showMonth, showYear, navigate])

  useEffect(() => {
    registrations?.data?.at(1)?.hourRate && setHourRateInput(registrations.data.at(1).hourRate)
  }, [registrations, setHourRateInput])

  if (isPending) return <Spin />
  if (!registrations) return <Spin />
  const { data: month, ...stats } = registrations
  if (!showYear || !showMonth || (isSuccess && !month?.length)) navigate(`/`)

  const uniqueWeekNumbers = []
  month.forEach(({ weekNumber }) => {
    if (uniqueWeekNumbers.indexOf(weekNumber) === -1) {
      uniqueWeekNumbers.push(weekNumber)
    }
  })

  const currentMonthFormatted = format(new Date(showYear, showMonth - 1), 'MMMM')

  return (
    <div>
      <BlurScreenWhenInactive />
      <Navbar />
      <NavMonths year={showYear} month={showMonth} setMonth={setShowMonth} setYear={setShowYear} />
      <CenteredContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title style={{ marginTop: 0 }}>
            Invoice for {currentMonthFormatted} {showYear}
          </Title>
          <span>
            <Input
              placeholder="Hour Rate"
              value={hourRateInput}
              addonBefore="Hour Rate: "
              style={{ width: 200 }}
              suffix="NOK"
              status={!hourRateUpdated && 'warning'}
              onChange={(e) => !isNaN(e.target.value) && setHourRateInput(e.target.value)}
            />
            <Button type="primary" onClick={() => setShowExport(true)} style={{ marginLeft: '2rem' }}>
              Export
            </Button>
          </span>
        </div>
        <RenderDays month={month} stats={stats} uniqueWeekNumbers={uniqueWeekNumbers} />
        <div className={`month-summary-wrapper`}>
          <div>
            <h3 className="col1">Total working days: {stats?.workingDaysTotalMonth}</h3>
            <h3 className="col1">Average hours/day: {(stats?.workingHoursTotalMonth / stats?.workingDaysTotalMonth).toFixed(2)}</h3>
          </div>
          <div>
            <h3 className="col2">Total hours: {stats?.workingHoursTotalMonth}</h3>
            <h3 className="col2">Overtime: {stats?.overtimeHoursTotalMonth}</h3>
          </div>
          <div>
            <h3 className="col3">Invoice amount: {formatCurrency(stats?.earnedTotalMonth)}</h3>
            <h3 className="col3">Incl. MVA: {formatCurrency(stats?.earnedTotalMonth * 1.25)}</h3>
          </div>
          <h3 className="col4">Flexitime this month: {registrations.flexitimeHoursTotalMonth} hrs</h3>
        </div>
      </CenteredContainer>
      <MonthExport showModal={showExport} onCancel={() => setShowExport(false)}>
        <Title>
          Invoice for {currentMonthFormatted} {showYear}, {user.firstname} {user.lastname}
        </Title>
        <RenderDays month={month} stats={stats} uniqueWeekNumbers={uniqueWeekNumbers} isExport />
        <div className={`month-summary-wrapper`}>
          <h3 className="col1">Total working days: {stats?.workingDaysTotalMonth}</h3>
          <h3 className="col2">Total hours: {stats?.workingHoursTotalMonth}</h3>
          <h3 className="col3">Invoice amount ex MVA: {formatCurrency(stats?.earnedTotalMonth)}</h3>
          <h3 className="col4">Including MVA: {formatCurrency(stats?.earnedTotalMonth * 1.25)}</h3>
        </div>
        <Footer />
      </MonthExport>
    </div>
  )
}

export default Month
